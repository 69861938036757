/**
 * Template page for a static, text only page (imprint, legal notice, privacy policy, etc.)
 *
 */

// IMPORTS
import React from "react";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import {graphql} from "gatsby";
import moment from "moment";
import {SEO, GlobalStyle, NavigationHeader, ConsentBanner, ContactFooter} from "../components";
import {
  DflRegionType,
  NavigationOverlayStateType,
  navigationOverlayStates,
  px,
  stage,
  elementSizes,
  StaticPageResponseProps,
  baseRegular,
  baseBold,
  mediumBold,
  colors,
  baseItalic,
  breakpoints,
  MainContentWrapper,
} from "../models";
import {getDflRegionByTimezone, columnSpan, createMarkup, unslugify, toTitleCase} from "../util/util";
import {AppState} from "../store/";
import {
  setTimezoneDetectedAction,
  updateRegionAction,
  updateIsNavigationHiddenAction,
  updateNavigationOverlayAction,
  updateIsNavigationInvertedAction,
  updateUTCOffsetAction,
  updateIsHomepageAboveVideoAction,
} from "../store/system/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  isNavigationHidden: boolean;
  isTextFilterActive: boolean;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
  timezoneDetected: state.system.timezoneDetected,
  isNavigationHidden: state.system.isNavigationHidden,
  isTextFilterActive: state.system.isTextFilterActive,
});

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateIsNavigationHidden: (isHidden: boolean) => void;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  updateNavigationInverted: (isInverted: boolean) => void;
  updateIsHomepageAboveVideo: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateIsNavigationHidden: (isHidden: boolean) => {
      return dispatch(updateIsNavigationHiddenAction(isHidden));
    },
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
    updateNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsHomepageAboveVideo: (isInverted: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isInverted));
    },
  };
};

// STYLES

/*
  make static pages at least full screen height. Some pages like the imprint can have
  very little content, which make the footer not appear at the bottom of the page
 */
const StaticPageWrapper = styled.div`
  height: 100vh;
  overflow: visible;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div`
  width: 100%;
  margin: 0;
  padding: 80px 22px 0;
  box-sizing: border-box;

  * {
    ${baseRegular};
    font-style: normal !important;
    text-decoration: none !important;
    padding: 0;
  }
  strong,
  h4,
  h5,
  h6 {
    ${baseBold};
  }
  em {
    ${baseItalic};
    font-style: italic !important;
  }

  h1 {
    ${mediumBold};
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 62px;
  }
  h2 {
    ${mediumBold};
    font-size: 20px;
    margin-top: 36px;
    margin-bottom: 32px;
  }
  h3 {
    ${baseBold};
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ul {
    padding-left: 16px;
  }
  ul > li {
    position: relative;
  }
  ul > li:before {
    content: "";
    position: absolute;
    top: 7px;
    left: -12px;
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${colors.black};
  }

  a {
    text-decoration: underline !important;

    &:hover {
      color: ${colors.redDFL};
    }
  }

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    width: ${px(stage.innerWidthTablet)};
    margin: 0 auto;

    h1 {
      ${mediumBold};
      text-align: center;
    }
  }

  /* desktop HD */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(columnSpan(8, stage.columnWidth, stage.columnGap))};
    margin: 0 auto;
    padding: ${px(elementSizes.navigationHeaderHeight)} 0 0 0;

    h1 {
      ${mediumBold};
      text-align: center;
    }
  }
`;

const FooterWrapper = styled.div`
  margin-top: 96px;
`;

// COMPONENT
interface ProductTemplateProps extends PropsFromState, PropsFromDispatch {
  data: StaticPageResponseProps;
}
class ProductTemplate extends React.Component<ProductTemplateProps> {
  constructor(props: ProductTemplateProps) {
    super(props);
  }

  // Product category page initialization
  componentDidMount() {
    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);
      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        this.props.updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
      }
      this.props.setTimezoneDetected();
    }

    // set navigation to black (could be inverted, coming from landing page)
    this.props.updateNavigationInverted(false);
    this.props.updateIsHomepageAboveVideo(false);

    // unhide the menu if it is hidden
    this.props.updateIsNavigationHidden(false);

    // hide overlay
    this.props.updateNavigationOverlay(navigationOverlayStates.default);
  }

  render() {
    const {data} = this.props;
    const content = createMarkup(data.wpPage.staticPage.staticpageContent);

    return (
      <StaticPageWrapper>
        <GlobalStyle />
        {/* @ts-ignore */}
        <NavigationHeader isTextDark />
        <ConsentBanner />
        <SEO title={toTitleCase(unslugify(data.wpPage.staticPage.slug))} />
        <MainContentWrapper>
          <ContentWrapper>
            <Content dangerouslySetInnerHTML={content} />
            <FooterWrapper>
              <ContactFooter />
            </FooterWrapper>
          </ContentWrapper>
        </MainContentWrapper>
      </StaticPageWrapper>
    );
  }
}

export const query = graphql`
  query($id: Int!) {
    wpPage(databaseId: {eq: $id}) {
      staticPage {
        staticpageContent
        slug
      }
    }
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTemplate);
